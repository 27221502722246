<template lang="pug">
div
  .container-fluid
    .row
      .col
        h2.text-center {{plateName}}
    .row      
      .d-xs-none.col-sm
      .col-xs-12.col-sm-5
        .form-group
          label(for="house") Назва вулиці
          StreetName(
            ref="search"
            )
        .form-group
          label(for="house") Будинок
          input#house.form-control(type="text", maxlength="10", v-model='store.inputNumber')
      .col-xs-12.col-sm-5
        .form-group
          label(for="entrance") Тип входу
          select#entrance.custom-select(v-model='store.entrType')
            option(v-for="mainType in mainTypes" v-bind:value="{ id: mainType.id, text: mainType.name, textTranslate: mainType.translate }") {{ mainType.name }}
        .form-group
          .form-row
            .col
              label(for="house") Номер входу
              input#house.form-control(type="text", maxlength="3", v-model='store.entranceNumberInput')
            .col
              label Квартири
              .input-group
                  input#apartments-min.form-control(type="text" maxlength="4" autocomplete="off" v-model = 'store.aptStartInput')
                  input#apartments-max.form-control(type="text" maxlength="4" autocomplete="off" v-model = 'store.aptEndInput')
      .d-xs-none.col-sm
  
  BulkDownloadList()

  .container-fluid
    .row
      .col
        // SVG defs
        svg(
          width="0",
          height="0",
          viewBox="0,0,0,0",
          viewport xmlns="http://www.w3.org/2000/svg"
        )
          SvgDefs(            
              :plateWidth = "this.plateWidth"            
              :clipWidth = '1'
              )
    .row
      .d-xs-none.d-sm-none.col-sm
      .col.preview
        // SVG Placeholder
        .containerPlaceholder(
          v-if="isPlaceholderStreet"
          )
        
          svg.svgPlaceholder(width="270", height="160", viewBox="-20 -20 540 320",:class="{ material: isMaterialStreet}", @click="isMaterialStreet = !isMaterialStreet", viewport xmlns="http://www.w3.org/2000/svg")            
              
            rect.plate(width="500" height="280" rx="16" ry="16" fill="#fff" stroke="#000" stroke-width=".25")

            text.prefix(x="52" y="54", font-size="25" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .035em; fill: #000;") {{mainFamilyPlace}}
                     
            text.nameRow1(x="48" y="124", font-size="60" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .01em; fill: #000;") {{mainNameRow1Place}}
            text.nameRow2(x="48" y="194", font-size="60" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .01em; fill: #000;") {{mainNameRow2Place}}
              tspan(style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .025em; fill: #000;") &nbsp;{{mainNumPlace}}
              

            text.translit(x="52" y="245", font-size="25" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .0025em; fill: #000;") {{mainTranslitRowPlace}}

            g#fix(v-if ="store.fixersDisplay") 
              circle(cx="16" cy="16" r="3" fill="#aaa")
              circle(cx="484",cy="16" r="3" fill="#aaa")
              circle(cx="484",cy="264",r="3" fill="#aaa")
              circle(cx="16",:cy="264",r="3" fill="#aaa")        

        // SVG Template entrance address
        .containerTemplate(
          style="position: relative;"
          v-else)

          .cartPreview(
            :class="{ collection: isCollection}",
            ref="refCartAddr",
            :style="'top:'+ this.rectAddrY +'px; right: calc(100vw - '+ this.rectAddrR +'px); width:'+ this.rectAddrW +'px; height:'+ this.rectAddrH +'px;'",
            v-html="animationBuffAddr"                      
            )

          svg#svgTemplateAddr(
            width="270", 
            height="160", 
            viewBox="-20 -20 540 320", 
            :class="{ material: isMaterialStreet}", 
            @click="isMaterialStreet = !isMaterialStreet", 
            viewport xmlns="http://www.w3.org/2000/svg",
            preserveAspectRatio="xMinYMin meet")   
              
            rect.plate(width="500" height="280" rx="16" ry="16" fill="#fff" stroke="#000" stroke-width=".25" ref="refAddr")
            
            g#refElements(style="display:none;")
              rect(ref="refLimit",:width="500-this.plateLimit", height="280",:x="40" y="0" fill="none" stroke="none")

            text.prefix(x="52" y="54", font-size="25" text-anchor="start" style="font-weight: 400; font-family: DniproCity-SemiBold; letter-spacing: .04em; fill: #000;") {{mainFamily}}
            
            g(ref="refName")
              text.nameRow1(x="48" y="124", :font-size="this.fontNameSize" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .01em; fill: #000;") {{mainNameRow1}}
              text.nameRow2(x="48" y="194", :font-size="this.fontNameSize" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .01em; fill: #000;") {{mainNameRow2}} 
                tspan(style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .025em; fill: #000;") {{store.mainNumber}}
              

            text.translit(x="52" y="245", font-size="25" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .035em; fill: #000;") {{store.mainTranslit}}

            g#fix(v-if ="store.fixersDisplay") 
              circle(cx="16" cy="16" r="3" fill="#aaa")
              circle(cx="484",cy="16" r="3" fill="#aaa")
              circle(cx="484",cy="264",r="3" fill="#aaa")
              circle(cx="16",:cy="264",r="3" fill="#aaa")

            SvgRequirements(            
              :plateWidth = "this.plateWidth"
              :plateHeight = "this.plateHeight"
              :plateName = "'Вхідний з адресою'"
              :isWhiteBack = "true"
              :immutableSize = "true"
              )

        .sign-size.text-center 
          span розмір покажчика
          | 500 x 280 мм     

      .col.preview
        // SVG Placeholder
        .containerPlaceholder(
          v-if="isPlaceholderEntrance"
          )
          svg.svgPlaceholder(width="270", height="160", viewBox="-20 -20 540 320", :class="{ material: isMaterialEntrance}", @click="isMaterialEntrance = !isMaterialEntrance", viewport xmlns="http://www.w3.org/2000/svg")            

            rect.plate(width="500" height="280" rx="16" ry="16" fill="#fff" stroke="#000" stroke-width=".25")

            text.entrance(x="52" y="54", font-size="25" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") {{store.entrType.text}}
            text.apt(x="286" y="54", font-size="25" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .018em; fill: #000;") квартири
            
            text.entranceNum(x="48" y="194", font-size="176" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .018em; fill: #000;") {{enterNumberPlace}}
            

            text.nameRow1(x="286" y="124", font-size="60" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .018em; fill: #000;") {{AptStartPlace}}
              tspan(x="286" dy="30" font-size="60" style="font-family: DniproCity-Regular; fill: #000;") —
            text.nameRow2(x="286" y="194", font-size="60" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .018em; fill: #000;") {{AptEndPlace}}         

            text.entranceEn(x="52" y="245", font-size="25" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .018em; fill: #000;") {{store.entrType.textTranslate}}
            text.aptEn(x="286" y="245", font-size="25" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .018em; fill: #000;") apartments


            g#fix(v-if ="store.fixersDisplay") 
              circle(cx="16" cy="16" r="3" fill="#aaa")
              circle(cx="484",cy="16" r="3" fill="#aaa")
              circle(cx="484",cy="264",r="3" fill="#aaa")
              circle(cx="16",:cy="264",r="3" fill="#aaa")

        // SVG Template entrance details
        .containerTemplate(
          style="position: relative;"
          v-else)

          .cartPreview(
            :class="{ collection: isCollection}",
            ref="refCartEntr",
            :style="'top:'+ this.rectEntrY +'px; right: calc(100vw - '+ this.rectEntrR +'px); width:'+ this.rectEntrW +'px; height:'+ this.rectEntrH +'px;'",
            v-html="animationBuffEntr"
            )

          svg#svgTemplateEntr(
            width="270", 
            height="160", 
            viewBox="-20 -20 540 320",
            :class="{ material: isMaterialEntrance}", 
            @click="isMaterialEntrance = !isMaterialEntrance", 
            viewport xmlns="http://www.w3.org/2000/svg",
            preserveAspectRatio="xMinYMin meet")
            
            rect.plate(width="500" height="280" rx="16" ry="16" fill="#fff" stroke="#000" stroke-width=".25" ref="refEntr")

            text.entrance(x="52" y="54", font-size="25" text-anchor="start" style="font-weight: 400; font-family: DniproCity-SemiBold; letter-spacing: .035em; fill: #000;") {{store.entrType.text}}
            text.apt(x="286" y="54", font-size="25" text-anchor="start" style="font-weight: 400; font-family: DniproCity-SemiBold; letter-spacing: .035em; fill: #000;") квартири
            
            text.entranceNum(x="48" y="194", font-size="176" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .015em; fill: #000;") {{store.entranceNumber}}
            

            text.nameRow1(x="286" y="124", :font-size="this.fontNameSize" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .025em; fill: #000;") {{store.aptStart}}
              tspan(x="286" dy="30" font-size="49.38" style="font-family: DniproCity-Regular; fill: #000;") —
            text.nameRow2(x="286" y="194", :font-size="this.fontNameSize" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Bold; letter-spacing: .025em; fill: #000;") {{store.aptEnd}}         

            text.entranceEn(x="52" y="245", font-size="25" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .025em; fill: #000;") {{store.entrType.textTranslate}}
            text.aptEn(x="286" y="245", font-size="25" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .025em; fill: #000;") apartments


            g#fix(v-if ="store.fixersDisplay") 
              circle(cx="16" cy="16" r="3" fill="#aaa")
              circle(cx="484",cy="16" r="3" fill="#aaa")
              circle(cx="484",cy="264",r="3" fill="#aaa")
              circle(cx="16",:cy="264",r="3" fill="#aaa")

            SvgRequirements(            
              :plateWidth = "this.plateWidth"
              :plateHeight = "this.plateHeight"
              :plateName = "'Вхідний з квартирами'"
              :isWhiteBack = "true"
              :immutableSize = "true"
              )

        .sign-size.text-center 
          span розмір покажчика
          | 500 x 280 мм
      .d-xs-none.d-sm-none.col-sm
    .row
      .col.svgContainer

  .container-fluid
    .row.row-cols-1.plate-options     
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3.text-center
        .form-group
          .custom-control.custom-switch
            input#fixMarks.custom-control-input(type="checkbox" v-model='store.fixersDisplay')
            label.custom-control-label(for="fixMarks") показати кріплення
    
    .row.plate-download
      .col.text-center
        button#btn-download.btn.btn-primary.btn-lg(type="button", :disabled="generateNotReady", @click="convertFunc(false)") Завантажити                
        button#add-cart.btn.btn-primary.btn-lg(type="button", :disabled="generateNotReady", @click="convertFunc(true)") Додати до набору
    .row.plate-download
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3.text-center        
        p.caption.text-center Макет у форматі .pdf з літерами у кривих
          br
          br
          | Під час завантаження матеріалів ви погоджуєтесь з ліцензією 
          a(href="https://creativecommons.org/licenses/by-nd/4.0/deed.uk" target="_blank") CC BY-ND 4.0 Із 
          | Зазначенням Авторства — Без Похідних 4.0 Міжнародна
    
    .svgInstance
      svg#svgInstanceAddr(width="500", height="450", viewBox="0 0 500 450", viewport xmlns="http://www.w3.org/2000/svg")

    .svgInstance
      svg#svgInstanceEntr(width="500", :height="450", viewBox="0 0 500 450", viewport xmlns="http://www.w3.org/2000/svg")
    

</template>

<script>
import { store } from '@/store.js';

import * as pdfFuncs from '@/svgToPdfConvertJS.js';

import StreetName from '@/components/StreetName.vue'
import SvgRequirements from '@/components/SvgRequirements.vue'
import SvgDefs from '@/components/SvgDefs.vue'
import BulkDownloadList from '@/components/BulkDownloadList.vue'

export default {
  name: 'Entrance',
  components: {
    StreetName,
    SvgRequirements,
    SvgDefs,
    BulkDownloadList,
  },

  data(){
    return{
      store,

      mainTypes: [
      {id: 1, name: 'корпус', translate: 'entrance'},      
      {id: 2, name: 'під’їзд', translate: 'entrance'},
      {id: 3, name: 'блок', translate: 'entrance'},
      {id: 4, name: 'вхід', translate: 'entrance'},
      {id: 5, name: 'секція', translate: 'section'},      
      ],

      mainFamily: '',
      mainNameRow1: '',
      mainNameRow2: '',
      mainTranslitRow: '',

      mainFamilyPlace: 'вулиця',
      mainNameRow1Place: 'Велика',
      mainNameRow2Place: 'Діївська,',
      mainTranslitRowPlace: 'Velyka Diivska St.',
      mainNumPlace: '12Б',
      enterNumberPlace: '24',
      AptStartPlace: '36',
      AptEndPlace: '72',

      isMaterialStreet: false,
      isMaterialEntrance: false,

      isPlaceholderStreet: true,
      isPlaceholderEntrance: true,

      generateNotReady: true,

      firstPart: '',
      firstPartIndex: '',
      separatorPart: '',
      secondPart: '',
      secondPartIndex: '',

      fontNameSizeMax:60,
      fontNameSizeMin:40,
      fontNameSize:60,
      fontOffset:5,

      plateWidth:500,
      plateHeight:280,      
      plateLimit:65,

      rectAddrR: 0,
      rectAddrY: 0,
      rectAddrW: 0,
      rectAddrH: 0,

      rectEntrR: 0,
      rectEntrY: 0,
      rectEntrW: 0,
      rectEntrH: 0,

      isCollection: false,

      plateName: 'Вхідні покажчики',

      animationBuffAddr: 0,
      animationBuffEntr: 0,
    }
  },

  watch:
  {
    'store.inputNumber': function()
    {
      this.wordSplit(store.inputNumber);
      store.mainNumber = this.firstPart + this.firstPartIndex + this.separatorPart + this.secondPart + this.secondPartIndex;
      store.mainNumber = store.mainNumber.toUpperCase();
      this.minReqStreet();
    },

    'store.inputStreetName': function()
    {
      this.minReqStreet();
    },

    'store.entranceNumberInput': function()
    {
      this.store.entranceNumber = this.numProcessing(store.entranceNumberInput);
      this.minReqEntrance();
    },

    'store.aptStartInput': function()
    {
      this.store.aptStart = this.numProcessing(store.aptStartInput);
      this.minReqEntrance();
    },

    'store.aptEndInput': function()
    {
      this.store.aptEnd = this.numProcessing(store.aptEndInput);
      this.minReqEntrance();
    },

    'store.bulkData.length': function()
    {
      if(this.store.bulkData.length > 0)
      {
        this.store.isCartEmpty = false;
      }
      else
      {
        this.store.isCartEmpty = true;
      }
    },
  },

  methods:
  {
    minReqStreet()
    {
      if(this.store.streetData.name != this.store.inputStreetName)
      {
        this.mainFamily = '';
        this.mainNameRow1 = '';
        this.mainNameRow2 = '';
        this.store.mainTranslit = '';
      }

      if(this.store.streetData.name == this.store.inputStreetName || this.store.mainNumber != '')
      {
        //this.generateNotReady = false;
        this.isPlaceholderStreet = false;
        this.isMaterialStreet = true;
        if(this.store.inputStreetName.length > 0 && this.store.streetData.name == this.store.inputStreetName)
        {
          this.streetDataSuggest();
        }
      }
      else
      {
        //this.generateNotReady = true;        
        this.isMaterialStreet = false;
        this.isPlaceholderStreet = true;
      }

      this.generationCheck();
    },

    numProcessing(inputNum)
    {
      var outputNum = inputNum;

      if(inputNum != '')
      {
        outputNum = outputNum.replace(/[^0-9]/gi, '');
        var lastSign = inputNum[inputNum.length - 1].replace(/[^0-9]/gi, '');
        if(lastSign == '')
        {
          outputNum = outputNum + inputNum[inputNum.length - 1].replace(/[a-z!? &{},.\\/:;"'|[\]@%$*\-+=~`><()#№]/gi, '');
        }
      }
      else
      {
        outputNum = '';
      }

      return outputNum.toUpperCase();
    },

    minReqEntrance()
    {
      if(this.store.entranceNumber != '' || this.store.aptStart != '' || this.store.aptEnd != '')
      {
        //this.generateNotReady = false;
        this.isPlaceholderEntrance = false;
        this.isMaterialEntrance = true;
      }
      else
      {
        //this.generateNotReady = true;        
        this.isMaterialEntrance = false;
        this.isPlaceholderEntrance = true;
      }

      this.generationCheck();
    },

    generationCheck()
    {
      var streetGenCheck = false;
      var entranceGenCheck = false;

      if(this.store.streetData.name == this.store.inputStreetName && this.store.mainNumber != '')
      {
        streetGenCheck = true;
      }

      if(this.store.entranceNumber != '' && this.store.aptStart != '' && this.store.aptEnd != '')
      {
        if(parseInt(this.store.aptStart.replace(/[^0-9]/gi, '')) < parseInt(this.store.aptEnd.replace(/[^0-9]/gi, '')))
        {
          entranceGenCheck = true;
        }
      }

      if(streetGenCheck && entranceGenCheck)
      {
        this.generateNotReady = false;
      }
      else
      {
        this.generateNotReady = true; 
      } 
    },

    streetDataSuggest() 
    {
      this.mainFamily = this.store.streetData.type;
      var buffArr = [];
      var streetNameBuff = this.store.streetData.name;

      this.mainNameRow1 = '';
      this.mainNameRow2 = '';

      this.store.mainTranslit = '';

      if(!this.store.streetData.name.replace(/([0-9])/g, '*').includes('*'))
      {
        if(this.store.streetData.name.includes(' ') || this.store.streetData.name.includes('-'))
        {
          if(!this.store.streetData.name.replace(/\w*([\d-]){2}\w*/, '*').includes('*'))
          {
            streetNameBuff = this.store.streetData.name.replace(/([-])/gi, '- ');
          }
          buffArr = streetNameBuff.split(' ');

          if(buffArr.length > 2)
          {
            if(buffArr[0].length + buffArr[1].length < buffArr[1].length + buffArr[2].length)
            {
              if(buffArr[0].includes('-'))
              {
                this.mainNameRow1 = buffArr[0];
              }
              else
              {
                this.mainNameRow1 = buffArr[0] + ' ';
              }
              if(buffArr[1].includes('-'))
              {
                this.mainNameRow1 += buffArr[1];
              }
              else
              {
                this.mainNameRow1 += buffArr[1] + ' ';
              }

              this.mainNameRow2 = buffArr[2];
            }
            else
            {
              if(buffArr[0].includes('-'))
              {
                this.mainNameRow1 = buffArr[0];
              }
              else
              {
                this.mainNameRow1 = buffArr[0] + ' ';
              }
              if(buffArr[1].includes('-'))
              {
                this.mainNameRow2 = buffArr[1];
              }
              else
              {
                this.mainNameRow2 = buffArr[1] + ' ';
              }

              this.mainNameRow2 += buffArr[2];
            }
          }
          else
          {
            if(buffArr[0].includes('-'))
            {
              this.mainNameRow1 = buffArr[0];
            }
            else
            {
              this.mainNameRow1 = buffArr[0] + ' ';
            }
            if(buffArr[1].includes('-'))
            {
              this.mainNameRow2 = buffArr[1];
            }
            else
            {
              this.mainNameRow2 = buffArr[1] + ' ';
            }
          }

        }
        else
        {
          this.mainNameRow1 = this.store.streetData.name;
        }
      }
      else
      {
        this.mainNameRow1 = this.store.streetData.name;
      }

      if(this.mainNameRow2 != '')
      {
        this.mainNameRow2 = this.mainNameRow2.substring(0, this.mainNameRow2.length);
        this.mainNameRow2 = this.mainNameRow2.trim();
        this.mainNameRow2 += ',';
      }
      else
      {
        this.mainNameRow1 += ',';
      }

      this.store.mainTranslit = this.store.streetData.translation;
    },

    wordSplit(inputedWord)
    {
      inputedWord = inputedWord.trim();
      this.firstPart = '';
      this.firstPartIndex = '';
      this.separatorPart = '';
      this.secondPartBeginIndex = '';
      this.secondPart = '';
      this.secondPartIndex = '';
      var separatorCheck = false;
      var separatorPlace = 0;
      //var firstPartIndexCheck = false;
      //var secondPartIndexCheck = false;
      inputedWord = inputedWord.replace(/([a-z])|([$&+,:.;=?@#|'<>^*()%!\u0400–\u04FF])/gi, '');

      for(let i = 0; i < inputedWord.length; i++)
      {
        if(((inputedWord[i] == '/') || (inputedWord[i] == '-') || (inputedWord[i] == ' ')) && (separatorCheck == false))
        {
          separatorCheck = true;
          separatorPlace = i;
          this.separatorPart = inputedWord[i];
        }
      }

      if(separatorCheck)
      {
        //First part
        for(let i = 0; i < separatorPlace; i++)
        {
          this.firstPart += inputedWord[i];
        }

        //removes all characters before numbers
        var counter = 0;
        if(this.firstPart.length > 0)
        {
          while(this.firstPart[counter].replace(/([\D])/gi, '') == '' && this.firstPart.length - 1 > counter)
          {
            counter++;
          }
          if(counter > 0)
          {
            this.firstPart = this.firstPart.substring(counter, this.firstPart.length);
          }

          //Saves index and removes other characters after it
          counter = 0;
          while(this.firstPart[counter].replace(/([\d])/gi, '') == '' && this.firstPart.length - 1 > counter)
          {
            counter++;
          }

          if(this.firstPart[counter].replace(/([\d])/gi, '') != '')
          {
            this.firstPartIndex = this.firstPart[counter];
            this.firstPart = this.firstPart.substring(0, counter);
          }
        }

        if(this.firstPart.length > 3)
        {
          this.firstPart = this.firstPart.substring(0, 3);
        }

        this.firstPartIndex = this.firstPartIndex.toUpperCase();

        //Second part
        for(let i = separatorPlace; i < inputedWord.length; i++)
        {
          this.secondPart += inputedWord[i];
        }

        if(this.secondPart.length > 1)
        {
          this.secondPart = this.secondPart.replace(/([\u0400–\u04FF])|([a-z$&+,.:;=?@#|'<>^*()%!/ -])/gi, '');

          //removes all characters before numbers except first character
          counter = 0;
          while(this.secondPart[counter].replace(/([\D])/gi, '') == '' && this.secondPart.length - 1 > counter)
          {
            counter++;
          }
          if(counter > 0)
          {
            this.secondPartBeginIndex = this.secondPart[0];
            this.secondPart = this.secondPart.substring(counter, this.secondPart.length);
            this.secondPartBeginIndex += this.secondPart;
            this.secondPart = this.secondPartBeginIndex;
          }
          
          if(this.secondPart.length > 1)
          {
            //Saves index and removes other characters after it
            if(this.secondPartBeginIndex == '')
            {
              counter = 0;
            }
            else
            {
              counter = 1;
            }

            while(this.secondPart[counter].replace(/([\d])/gi, '') == '' && this.secondPart.length - 1 > counter)
            {
              counter++;
            }

            if(this.secondPart[counter].replace(/([\d])/gi, '') != '')
            {
              this.secondPartIndex = this.secondPart[counter];
              this.secondPart = this.secondPart.substring(0, counter);
            }
          }

          if(this.secondPart.length == 1 && this.secondPart[0].replace(/([\D])/gi, '') == '')
          {
            this.secondPartIndex = '';
          }

          if(this.secondPart.length > 3 && this.secondPartBeginIndex == '')
          {
            this.secondPart = this.secondPart.substring(0, 3);
          }
          else if(this.secondPart.length > 4 && this.secondPartBeginIndex != '')
          {
            this.secondPart = this.secondPart.substring(0, 4);
          }

          this.secondPartIndex = this.secondPartIndex.toUpperCase();
        }
        else
        {
          this.secondPart = this.secondPart.substring(0, 0);
        }
      }
      else
      {
        for(let i = 0; i < inputedWord.length; i++)
        {
          this.firstPart += inputedWord[i];
        }

        var lettersBeforeNumbersCheck = true;
        for(let i = 0; i < this.firstPart.length; i++)
        {
          if(this.firstPart[i].replace(/([\D])/gi, '') != '')
          {
            lettersBeforeNumbersCheck = false;
          }
        }
        if(lettersBeforeNumbersCheck)
        {
          this.firstPart = '';
        }

        //removes all characters before numbers
        counter = 0;
        if(this.firstPart.length > 0)
        {
          while(this.firstPart[counter].replace(/([\D])/gi, '') == '' && this.firstPart.length - 1 > counter)
          {
            counter++;
          }
          if(counter > 0)
          {
            this.firstPart = this.firstPart.substring(counter, this.firstPart.length);
          }

          //Saves index and removes other characters after it
          counter = 0;
          while(this.firstPart[counter].replace(/([\d])/gi, '') == '' && this.firstPart.length - 1 > counter)
          {
            counter++;
          }

          if(this.firstPart[counter].replace(/([\d])/gi, '') != '')
          {
            this.firstPartIndex = this.firstPart[counter];
            this.firstPart = this.firstPart.substring(0, counter);
          }

          if(this.firstPart.length > 3)
          {
            this.firstPart = this.firstPart.substring(0, 3);
          }

          this.firstPartIndex = this.firstPartIndex.toUpperCase();
        }
      }

    },

    getTestWidth() {
      const waveBox = document.querySelector('#refWave');
      const digitBox = document.querySelector('#refDigit');
      this.testWidth = digitBox.getBoundingClientRect().width + " vs. " + parseInt(waveBox.getBoundingClientRect().width);
    },

    convertFunc(isBulk)
    {
      let pdfMainName = (this.store.mainTranslit).toLowerCase().replace(/ /gm,'-').replace('.','') + '-' + this.store.mainNumber + 
      '_id' + this.store.entrType.id + '_' + this.store.entranceNumber + '_' + this.store.aptStart + '-' + this.store.aptEnd;

      let pdfNameOptions = '_addr_entrance-sign';
      if(this.store.fixersDisplay)
      {
        pdfNameOptions += '_FIX';
      }
      pdfNameOptions += '.pdf';


      pdfFuncs.svgToPdfCovert(20, 20, 500, 450, 540, 460, 
      pdfMainName, pdfNameOptions, 36, 315, this.plateHeight, 'svgInstanceAddr', 'svgTemplateAddr', isBulk, this.store.bulkData);

      const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms))
      }

      sleep(500).then(() => {
        pdfNameOptions = '_apart_entrance-sign';

        if(this.store.fixersDisplay)
        {
          pdfNameOptions += '_FIX';
        }
        pdfNameOptions += '.pdf';

        pdfFuncs.svgToPdfCovert(20, 20, 500, 450, 540, 460, 
        pdfMainName, pdfNameOptions, 36, 315, this.plateHeight, 'svgInstanceEntr', 'svgTemplateEntr', isBulk, this.store.bulkData);
      })
      
      

      if(isBulk)
      {
        this.getPosition();

        this.animationBuffAddr = document.querySelector('#svgTemplateAddr').outerHTML;
        this.animationBuffEntr = document.querySelector('#svgTemplateEntr').outerHTML;

        let isCopy = false;

        if(typeof this.store.bulkData.length !== 'undefined')
        {
            for(let i = 0; i < this.store.bulkData.length; i++)
            {
                if(this.store.bulkData[i].pdfName == (pdfMainName + pdfNameOptions))
                {
                    isCopy = true;
                }
            }
        }

        if(!isCopy)
        {
          setTimeout(() => {
            this.isCollection = true;
          }, 100); 
          setTimeout(() => {
            this.animationBuffAddr = 0;
            this.animationBuffEntr = 0;
            this.isCollection = !this.isCollection;        
          }, 1000);
        }
      }
    },

    getPosition() {      
      this.rectAddrY = parseInt(this.$refs.refAddr.getBoundingClientRect().y);
      this.rectAddrW = parseInt(this.$refs.refAddr.getBoundingClientRect().width);
      this.rectAddrH = parseInt(this.$refs.refAddr.getBoundingClientRect().height);
      this.rectAddrR = parseInt(this.$refs.refAddr.getBoundingClientRect().right);

      this.rectEntrY = parseInt(this.$refs.refEntr.getBoundingClientRect().y);
      this.rectEntrW = parseInt(this.$refs.refEntr.getBoundingClientRect().width);
      this.rectEntrH = parseInt(this.$refs.refEntr.getBoundingClientRect().height);
      this.rectEntrR = parseInt(this.$refs.refEntr.getBoundingClientRect().right);        
    },

    sizeXR(offset)
    {
      return this.plateWidth - offset;
    },

    inputField() {
      this.$refs.search.$refs.inputField.focus();      
    },

    getWidth() {      
      // limitBox – Size of the limit box for the text
      // offsetBox – Size of the offset for the calculation
      // contentBox – Size of the text box 
      if(this.store.inputStreetName != '')
      {
        var limitBox = this.$refs.refLimit.getBoundingClientRect().width;
        var contentBox = this.$refs.refName.getBoundingClientRect().width;
      }         

      if(limitBox < contentBox) {      
        return this.fontNameSize - this.fontOffset;
      }
      else if((limitBox > contentBox) && (this.fontNameSize == this.fontNameSizeMax)) {
        return this.fontNameSize;
      }
      else if((limitBox > contentBox) && (this.fontNameSize < this.fontNameSizeMax) && ((limitBox - contentBox) > 30)) {        
        return this.fontNameSize + this.fontOffset;
      }     
      else {        
        return this.fontNameSize;
      }
    },
  },
  beforeMount() {
    if (this.store.mainTranslit) {
      this.isPlaceholderStreet = false;
      this.isMaterialStreet = true;
    }    
  },

  mounted() {
    this.inputField();
    this.wordSplit(store.inputNumber);
    store.mainNumber = this.firstPart + this.firstPartIndex + this.separatorPart + this.secondPart + this.secondPartIndex;
    store.mainNumber = store.mainNumber.toUpperCase();
    this.minReqStreet();
    this.minReqEntrance();
  },

  updated(){
    this.$nextTick(()=>{
      if(!this.isPlaceholder) {
        this.fontNameSize = this.getWidth();
      }
    })
  },
}

</script>

<style lang="scss" scoped>
@import '@/assets/styles/_svg-templates.scss';

.container-fluid {
  max-width: 700px;
}

.preview {
  min-height: 220px;
  svg {
    margin-left: auto;
    margin-right: auto;
  }
}

.cartPreview {  
  display: inline-block; 
  width: 250px;
  height: 140px;

  position: fixed;
  top: 200px;
  right: calc(50% - 300px);
  
  visibility: hidden;
  opacity: 1;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 4px 80px 0 rgba(0,0,0,.3);  
  border: none;

  &.collection {    
    visibility: visible;
    top: 50px !important;
    right: 50px !important;
    // background-color: rgba(255,255,255,1);
    
    opacity: 0;
    // z-index: 10000;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.3);

    transform: scale(.1);
    transform-origin: top right;

    transition: top .5s cubic-bezier(0, 0, 0.3, 1.8), right .5s ease-out, transform .5s ease-out, opacity .75s ease-in, box-shadow .5s ease-out;

  }
}

</style>